var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("form-row", {
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function () {
                return [
                  _c(
                    "sun-label-group",
                    { attrs: { text: "Category" } },
                    [
                      _c("sun-select", {
                        attrs: {
                          value: _vm.value.category,
                          options: _vm.categoriesList,
                          "track-by": "id",
                          label: "name",
                          required: "required",
                          "text-error": _vm.textError,
                          error: _vm.formErrors.category,
                          "close-on-select": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeCategory($event.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "right",
              fn: function () {
                return [
                  _c(
                    "sun-label-group",
                    { attrs: { text: "Subcategory" } },
                    [
                      _c("sun-select", {
                        attrs: {
                          value: _vm.value.subcategory,
                          options: _vm.subcategoriesList,
                          disabled:
                            !_vm.isCategorySelected ||
                            _vm.value.category.uncategorized,
                          "track-by": "id",
                          label: "name",
                          "group-label": "name",
                          "group-values": "subcategories",
                          "close-on-select": "",
                          required: "required",
                          "text-error": _vm.textError,
                          error: _vm.formErrors.subcategory,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeSubCategory($event.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3937379874
        ),
      })
    : _c("form-row", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader")]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader")]
            },
            proxy: true,
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }