<template>
  <form-row v-if="!isLoading">
    <template #left>
      <sun-label-group text="Category">
        <sun-select
          :value="value.category"
          :options="categoriesList"
          track-by="id"
          label="name"
          required="required"
          :text-error="textError"
          :error="formErrors.category"
          close-on-select
          @change="changeCategory($event.value)"
        />
      </sun-label-group>
    </template>
    <template #right>
      <sun-label-group text="Subcategory">
        <sun-select
          :value="value.subcategory"
          :options="subcategoriesList"
          :disabled="!isCategorySelected || value.category.uncategorized"
          track-by="id"
          label="name"
          group-label="name"
          group-values="subcategories"
          close-on-select
          required="required"
          :text-error="textError"
          :error="formErrors.subcategory"
          @change="changeSubCategory($event.value)"
        />
      </sun-label-group>
    </template>
  </form-row>
  <form-row v-else>
    <template #left>
      <line-loader></line-loader>
    </template>
    <template #right>
      <line-loader></line-loader>
    </template>
  </form-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { ERRORS } from '@/i18n/forms/errors';
import LineLoader from '@/components/atoms/Loaders/LineLoader';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'CategorySelector',
  components: { FormRow, LineLoader },
  props: {
    // TODO this property is a entity, should be only category and subcategory
    value: { type: Object, default: () => {} },
    error: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: true,
    hasError: false,
    textError: ERRORS.en.required,
    entity: null,
    formErrors: {
      category: false,
      subcategory: false,
    },
  }),
  computed: {
    ...mapGetters({
      categories: CATEGORIES,
    }),
    isCategorySelected() {
      return !!this.value?.category?.name;
    },
    categoriesList() {
      return deepClone(this.categories);
    },
    subcategoriesList() {
      return this.value?.category ? [this.value?.category] : [];
    },
  },
  watch: {
    value: {
      deep: true,
      handler(entity) {
        this.entity = deepClone(entity);
      },
    },
    error(value) {
      this.formErrors.category = value;
      this.formErrors.subcategory = value;
    },
  },
  created() {
    this.getcategoriesAndSubcategories();
    this.entity = deepClone(this.value);
    this.isLoading = false;
  },
  methods: {
    ...mapActions({ getcategoriesAndSubcategories: GET_CATEGORIES_REQUEST }),
    changeCategory(event) {
      this.formErrors.category = false;
      this.entity.category = event;
      if (event?.uncategorized) {
        this.entity.subcategory = event.subcategories[0];
      } else {
        this.entity.subcategory = null;
      }
      this.emitInput();
    },
    changeSubCategory(event) {
      this.formErrors.subcategory = false;
      this.entity.subcategory = event || null;
      this.emitInput();
    },
    emitInput() {
      this.$emit('input', this.entity);
    },
  },
};
</script>
